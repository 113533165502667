<template>
  <div>
    <ViewTemplateWithTable :panel="panel" :templateList="templateList" :buttonRemove="hasRemove" :filters="filters">
      <div slot="content-buttons">
        <Button _key="btnInvoiceCreate" title="Fatura" classIcon="fas fa-plus-circle" type="primary" size="small"
          eventName="invoiceCreate" :clicked="create" />
      </div>
      <div slot="content-buttons-table-header">
        <Button _key="btnGenerateInvoice" :title="'Gerar ' + selected.length" type="primary" size="small"
          :clicked="confirmGenerate" />
      </div>
    </ViewTemplateWithTable>
    <CancelInvoice />
    <Modal title="Tem certeza que deseja gerar?" :width="500" v-show="showModal('generateInvoice')">
      <Alert type="warning">
        <span>
          Atenção, certifique-se que está gerando a Fatura correta, após gerada
          não será permitido alterações
        </span>
      </Alert>
      <Confirmation :isModal="false" title="Você tem certeza?" type="primary" :confirmed="generate" />
    </Modal>
  </div>
</template>

<script>
import ViewTemplateWithTable from "@nixweb/nixloc-ui/src/component/template/ViewTemplateWithTable.vue";
import Confirmation from "@nixweb/nixloc-ui/src/component/shared/Confirmation.vue";
import Alert from "@nixweb/nixloc-ui/src/component/layout/Alert";
import Modal from "@nixweb/nixloc-ui/src/component/forms/Modal";
import Button from "@nixweb/nixloc-ui/src/component/forms/Button";

import CancelInvoice from "./CancelInvoice.vue";

import { mapGetters, mapMutations, mapActions, mapState } from "vuex";

export default {
  name: "InvoiceListView",
  components: {
    ViewTemplateWithTable,
    Confirmation,
    Alert,
    Modal,
    Button,
    CancelInvoice,
  },
  data() {
    return {
      id: "",
      panel: {
        module: "Faturamento",
        title: "Fatura",
        showFilter: true,
        showSearch: true,
        showButtons: true,
      },
      templateList: {
        urlGetApi: "/api/v1/billing/invoice/get-all",
        urlGenerateAllApi: "/api/v1/billing/invoice/generate",
        urlDeleteAllApi: "/api/v1/billing/invoice/delete",
        showChecks: true,
        headerTable: [
          {
            title: "Número",
            field: "number",
            type: "text",
            iconSearch: false,
          },
          {
            title: "Emissão",
            field: "registeredDate",
            type: "date",
            iconSearch: false,
            classCssTitle: "text-center",
            classCssBody: "text-center",
          },
          {
            field: "fullName",
            title: "Cliente",
            type: "link",
            routeName: "invoiceUpdate",
            iconSearch: true,
          },
          {
            field: "statusName",
            title: "Status",
            type: "class",
            fieldComparison: "statusName",
            classCssTitle: "text-center",
            classCssBody: [
              {
                classCss: "text-center badge badge-warning",
                fieldComparison: "Pendente",
              },
              {
                classCss: "text-center badge badge-primary",
                fieldComparison: "Gerada",
              },
              {
                classCss: "text-center badge badge-danger",
                fieldComparison: "Cancelada",
              },
            ],
          },
          {
            title: "Valor",
            field: "value",
            classCssTitle: "text-center",
            classCssBody: "text-center",
            type: "currency",
          },
          {
            type: "button",
            field: "statusName",
            ifFieldEqual: "Gerada",
            typeButton: "default",
            button: {
              classIcon: "fa-regular fa-ban",
              type: "danger",
              size: "small",
              eventName: "cancelInvoice",
            },
            classCssTitle: "text-center",
            classCssBody: "text-center",
          },
        ],
      },
      filters: [
        {
          type: "date",
          title: "Emissão",
          field: "RegisteredDate",
          value: "",
        },
        {
          type: "options",
          title: "Status",
          field: "StatusName",
          options: [
            { text: "Cancelada", value: "Cancelada" },
            { text: "Gerada", value: "Gerada" },
            { text: "Pendente", value: "Pendente" },
          ],
          value: [],
        },
        {
          type: "decimal",
          title: "Valor",
          field: "Value",
          valueStart: 0,
          valueEnd: 0,
        },
      ],
    };
  },
  computed: {
    ...mapGetters("generic", ["showModal", "event"]),
    ...mapState("generic", ["selected"]),
    ...mapState("user", ["userLogged"]),
    hasRemove() {
      if (this.userLogged.user.userName == 'UserTemp') return true;
      return false;
    }
  },
  methods: {
    ...mapMutations("generic", ["openModal", "hideModal", "removeLoading"]),
    ...mapActions("generic", ["putAllApi", "deleteAllApi"]),
    create() {
      this.$router.push({
        name: "invoiceCreate",
      });
      this.removeLoading(["btnInvoiceCreate"]);
    },
    confirmGenerate() {
      this.removeLoading(["btnGenerateInvoice"]);
      this.openModal("generateInvoice");
    },
    generate() {
      let params = {
        url: this.templateList.urlGenerateAllApi,
        selected: this.selected,
      };
      this.putAllApi(params).then((response) => {
        if (response.success) {
          this.hideModal("generateInvoice");
        }
        this.removeLoading(["confirm"]);
      });
    },
  },
};
</script>
<style>
.invoice-pending {
  color: #F1BC31;
}

.invoice-canceled {
  color: red;
}

.invoice-generated {
  color: #017AFF;
}
</style>